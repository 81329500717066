@import url("https://fonts.googleapis.com/css2?family=Changa+One&display=swap");


html{
  margin: 0;
height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* display: grid;
  height: 100vh; */
  /* grid-template-rows: auto 1fr; */
}

#exampleModal, #exampleModal2, #exampleModal3, #exampleModal4, #exampleModal5, #exampleModal6{
	z-index: 3;
	display:none;
position: absolute;
top: 40%;}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.todo__list{
  display:flex;
  justify-content: center;
  align-items: center;
  /* width: 60vw; */
  border: 1px solid lightgray;
  margin-bottom: 10px !important;
  
  }
  footer{
    padding:18px 0 2px 0;
    width: 100%;
    height:90px;
    background-color: #000;
    color:rgba(82, 159, 204);
    align-self: end;
    margin-top: auto;
    position: fixed;
    /* display: flex !important; */
    text-align: center;
    z-index: 3;
    bottom: 0 !important;
   
  }
  
  footer p em{
    font-style: normal;
  }
  
  footer a{
    color:whitesmoke;
    font-style: italic;
  }


  .homepage {
    margin: auto;
    overflow: auto;
    height: 100vh;
    width: 100vw;
    background-image: url('./../public/todo-bg.jpg');
    /* animation: gradient 15s ease infinite; */
    background-size: 90% 130%;
    background-attachment: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;

    /* animation: gradient 15s ease infinite; */
  }

  .homepage-h1{
    margin-top:0;
    margin-bottom:0;
    padding: 20px 0 0 0;
  } 
  
  .add-edit-input {
    z-index: 1;
    position: absolute;
    border-radius: 28px;
    top: 136px;
    right:38%;
    left:38%;
    outline: none;
    border: none;
    height: 30px;
    font-size: 25px;
    padding: 5px 58px 5px 10px;
    outline: 3px solid #529fcc;
  }

  .password-input, .email-input{
    border-radius: 18px;
    font-size:20px !important;
    width:280px !important;
    
  }

  .password-input:focus, .email-input:focus{
    outline: 1px solid #529fcc;
    background-color: #529fcc;
    color:whitesmoke !important;
    transition: all 0.1s ease-in;
  }

  .password-input:focus::placeholder, .email-input:focus::placeholder{
    outline: 1px solid #529fcc;
    background-color: #529fcc;
    color:whitesmoke !important;
  }
  
.victory-span{
  color:#3887c7;
}

  .add-confirm-icon {
    position: absolute;
    transform: scale(2);
    top: 132px;
    /* right: 40%; */
    left: 58%;
    cursor: pointer;
    z-index: 1;
  }
  

.footer-links{
  padding-right:7px;
  padding-left: 7px;
  font-size: 35px !important;
}

.footer-links-container{
  padding-top:6px;
  width:100%;
}

footer.footer{
  height: 120px !important;
}

.footer-links:hover{
 color:#529fcc;
 translate: 0px -4px;
 transition: all 0.2s ease;
}

  .todo {
    list-style-type:none;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin-bottom: 10px;
    word-wrap: break-word;
    /* margin-bottom: 10px; */
    margin-left: 34px;
    margin-right: 34px;
  }

  .todo:first-child{
    margin-top:90px;

  }
  
.plans-h3{
  padding-top:26px;
  text-align: center;
  font-size:22px;
}

  .todo > li {
    width: 500px;
    background-color: rgb(211, 211, 211);
    padding: 5px 10px;
  }

  .todo > li::before {
    content: attr(data-icon);
    /* Make slightly larger than the li font-size
    but smaller than the li gap */
    font-size: 1.25em;
  }
  
  .delete-button, .edit-button{
    cursor: pointer;
    margin-left: 10px;
    font-size:28px !important;
  }
  .delete-button {
    color:rgb(185, 88, 88);
  }

  .edit-button{
    color:rgba(82, 159, 204);
  }
  
  .logout-icon {
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 20px;
    background: transparent;
    color: black !important;
  }

  .modal-header{
    display: none;

  }
  .modal-body{
    align-items: center;
    flex-direction: column;
    display: flex;
   
    width: 100%;
  }

  .modal-title{
    color: whitesmoke;
    width: 90px;
    height:30px;
    text-align:center;
  }

  .modal-content{
    width: 100%;
    position: fixed;
    bottom: 0;
    display: flex;
    padding: 15px 14px 0px 14px;
    height: 118px;
    background-color: #222020;
    /* border-radius: 18px; */
    flex-direction: row;
    z-index: 3 !important;
    
  }
  

  .modal-button{
    background-color: rgba(82, 159, 204);
    width: 100px !important;
    height:40px !important;
    text-align: center;
    border-radius: 8px;
    font-size:16px;
  }
  .modal-footer{
    
    text-align: center;
  }

  .dialog-paragraph{
    color: whitesmoke;
    line-height: 2em;
    font-size: 18px;
    text-align:center;
  }

  button.btn-close{
    visibility: hidden;
  }

  .dialog-instructions{
    color: rgba(82, 159, 204);
  }

  .user-email-container {
    /* cursor: pointer; */
    position: absolute;
    top: 14px;
    right: 60px;
    padding: 20px;
    background: #000;
    color: white;
    padding-top:0;
    padding-bottom: 0;
  }
  
  .user-email{
color: #529fcc;
  }

  .logout-icon:hover {
    cursor: pointer;
    color:#529fcc;
  transition: all 0.2s ease;
  }

  

  .welcome {
    margin: auto;
    overflow: auto;
    height: 100vh;
    width:100% !important;
    /* background: linear-gradient(315deg, rgb(8, 1, 23) 3%, rgb(59, 61, 62) 38%, rgb(28, 29, 31) 68%, rgb(49, 49, 50) 98%); */
    /* animation: gradient 15s ease infinite; */
    background-size: 400% 400%;
    background-attachment: fixed;
  }

@keyframes gradient {
    0% {
        background-position: 0% 0%;
    }
    50% {
        background-position: 100% 100%;
    }
    100% {
        background-position: 0% 0%;
    }
}
  
  .welcome > h1 {
    font-family: "Changa One";
    font-weight: normal;
    font-size: 104px;
    margin: 0px auto 0 auto;
    padding: 16px 10px;
    position: static;
    background-color: #529fcc;
      color: whitesmoke;
    text-align: center;
    width:100%;
   
  }
  
  .login-register-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px 20px;
    /* left: 36%; */
    height: 100%;
    top: 0px;
    width: 100%;
    background-image: url("./components/welcome-view/img/background.jpg");
    background-size: 140% 150%;
    background-attachment: fixed;
  }
  
  .login-register-container > input {
    height: 30px;
    width: 80%;
    border: 1px solid gray;
    outline: none;
    margin-bottom: 15px;
    font-size: 25px;
    padding: 5px;
  }
  
  .login-register-container > button {
    padding: 5px 20px;
    border: none;
    margin-bottom: 5px;
    cursor: pointer;
  }
  
  .sign-in-register-button {
    background-color: #529fcc;
    font-size: 20px;
    margin-top: 10px;
    /* font-family: "Changa One"; */
    font-weight: normal;
    color: white;
  }
  
  .create-account-button {
    background: none;
    margin-top: 10px;
    font-size: 15px;
  }

  .todo-li{
    height:auto;
    font-size: 20px;
    background-color: #529fcc !important;
  }
  
  .todo-svg {
    position: absolute;
    bottom: 100px;
    right: 300px;
    transform: scale(1.3);
  }

  .todos-container{
    height: auto;
    /* padding-top: 100px; */
    overflow-y: auto;
    width:100%;
    padding-top: 0 !important;
      position:absolute;
      top:120px;
  }

  
/* scrollbar width */

::-webkit-scrollbar {
    width: 10px;
}

/* scrollbar Track */

::-webkit-scrollbar-track {
    background: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 1)), to(rgba(82, 159, 204, 1)));
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(82, 159, 204, 1) 100%);
    border-radius: 10px;
}

/* scrollbar Handle */

::-webkit-scrollbar-thumb {
    opacity: 0;
    /* -webkit-box-shadow: 0px 0px 0px 100000vh white; */
            /* box-shadow: 0px 0px 0px 0.5vh rgba(82, 159, 204); */
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: rgba(82, 159, 204, 1);
    -webkit-transition: all 1s ease-in-out;
    transition: all 1s ease-in-out;
}


  @keyframes fadeIn{
    0%{margin-top:-28px;
    
    }
  
    100%{
      margin-top:0px;
    }
  }

  .strike{text-decoration: line-through;}

  @media screen and (min-width:1088px){
    .add-confirm-icon{
      left:59% !important;
     }
  }
  
  @media screen and (max-width:500px){
    div.modal-body{
      padding-bottom: 30px !important;
  
    }
    .modal-content{
      height: 150px;
    }
    
  }


  @media screen and (max-width:329px){
.plans-h3{
  font-size: 19px;
}
  }



  @media screen and (max-width:431px){
    div.todo li{
      width:90%;
      margin-left:10px;
    }

    footer{
      position:relative !important;
      bottom:0 !important;
      margin-top: 120px;
     
    }
    .welcome > h1{
      font-size: 30px !important;
z-index: 1;
left:0;
top:0;    

}
    .login-register-container{
      /* position: relative; */
      left:0;
      right: 0;
      top:70px;
      /* position: relative; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px 20px 60px 20px;
  /* left: 36%; */
  height: 100%;
  /* top: 0px; */
  width: 100%;
  background-image: url("./components/welcome-view/img/background.jpg");
  background-size: 140% 150%;
  background-attachment: fixed;
  margin-bottom:0;
    }
    button.create-account-button {
      font-size:18px;
    }
    .homepage-h1{
      font-size: 24px;
    }
    div.todos-container{
      height: auto;
      overflow-y: auto;
      width:100%;
      padding-top: 0 !important;
      position:absolute;
      top:120px;
    }
   input.add-edit-input{
    width:78.5%;
    left:0;
    right:0;
    padding: 5px 58px 5px 10px;

   }
   .add-confirm-icon{
    left:85.5% !important;
    top: 143px !important;
   }
   .modal-content{
    width: 100%;
    position: fixed;
    bottom: 0;
    display: flex;
    padding: 15px 14px 80px 14px !important;
    height: 118px;
    background-color: #222020;
    border-radius: 18px;
    flex-direction: row;
    
  }
  .dialog-paragraph{
    padding: 15px 14px 10px 14px !important;
  }
 
  }

  @media screen and (max-height:1200px){

    /* .add-edit-input { */
      /* width:30%; */
        /* position: absolute;
        top: 36px;
        right:20%;
        left:20%;
        outline: none;
        border: none;
        height: 30px;
        font-size: 25px;
        padding: 5px 58px 5px 10px !important;
    
        padding: 5px 20px;
        outline: 3px solid #529fcc;
      } */
      
      .user-email-container {
        /* cursor: pointer; */
        position: absolute;
        top: 60px;
    
        left: 0;
        padding: 20px;
        background: #000;
        color: white;
        padding-top:0;
        padding-bottom: 0;
      }
    
      /* .add-confirm-icon {
        position: absolute;
        transform: scale(2);
        top: 42px;
        left: 75% !important;
        cursor: pointer;
      } */
    
        .welcome > h1 {
          font-family: "Changa One";
          font-weight: normal;
          font-size: 34px !important;
          left: 46px;
          top: 35px;
          padding: 7px 10px !important;
          position: absolute;
          background-color: #529fcc;
          color: whitesmoke;
        }
    
        .login-register-container {
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          padding: 20px 50px;
          top: 360px;
          left:100px;
          right:100px;
          background-image: url("./components/welcome-view/img/background.jpg");
        }
    
        .todo__list{
          width: 90vw;
          
          }
          .to-do-container{
            padding-left: 0;
          }
          div.todos-container{
            height: auto;
            overflow-y: auto;
            width:100%;
            padding-top: 0 !important;
            position:absolute;
            top:120px;
          }
    
          .homepage-h1{
            height: 86px;
          }
          .todo-li{
            width:75% !important;
            margin: 0 auto;
          }
          footer{
            position:relative !important;
            bottom:0 !important;
            margin-top: 120px;
           
          }
          .welcome > h1{
            z-index: 1;
    top: 0 !important;
    position: fixed !important;
    left: 0 !important;
    right: 0 !important;
    font-family: "Changa One";
    font-weight: normal;
    font-size: 104px;
    margin: 0px auto 0 auto;
    padding: 16px 10px;
    position: static;
    background-color: #529fcc;
    color: whitesmoke;
    text-align: center;
    width: 100%;
     
     }
          .login-register-container{
            /* position: relative; */
            
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px 20px;
    left: 0;
    height: 100%;
    top: 0px;
    width: 100%;
        background-image: url("./components/welcome-view/img/background.jpg");
        background-size: 140% 150%;
        background-attachment: fixed;
        margin-bottom:0;
          }
      }


      @media screen and (min-width:420px) and (max-width:820px){
        .add-confirm-icon {
          position: absolute;
          transform: scale(2);
          top: 141px;
          /* right: 40%; */
          left: 73% !important;
          cursor: pointer;
      }
  }
    


      @media screen and (min-width:820px){
footer{
  margin-top:0;
}
.add-confirm-icon {
  position: absolute;
  transform: scale(2);
  top: 143px;
  /* right: 40%; */
  /* left: 73.5%; */
  cursor: pointer;
}
      }
  @media screen and (max-width:820px){

.add-edit-input {
    position: absolute;
    top: 132px;
    right:20%;
    left:20%;
    outline: none;
    border: none;
    height: 30px;
    font-size: 25px;
    padding: 5px 58px 5px 10px !important;

    padding: 5px 20px;
    outline: 3px solid #529fcc;
  }
  
  .user-email-container {
    /* cursor: pointer; */
    position: absolute;
    top: 60px;

    left: 0;
    padding: 20px;
    background: #000;
    color: white;
    padding-top:0;
    padding-bottom: 0;
  }

  /* .add-confirm-icon {
    position: absolute;
    transform: scale(2);
    top: 152px;
    left: 84.5% !important;
    cursor: pointer;
  } */

    .welcome > h1 {
      font-family: "Changa One";
      font-weight: normal;
      font-size: 40px !important;
      left: 46px;
      top: 35px;
      padding: 10px 10px;
      position: absolute;
      background-color: #529fcc;
      color: whitesmoke;
    }

    .homepage-h1{
      padding: 10px 0 0 0 !important;
    }

    footer > p:first-child{
      margin-top:0 !important;
    }

    .login-register-container {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 0 0 10px 0 !important;
      top: 0 !important;
      left:0;
      right:0;
      height: 100%;
      padding: 102px 0px 60px 0px !important;

      /* right:100px; */
      background-image: url("./components/welcome-view/img/background.jpg");
    }

    .todo__list{
      width: 90vw;
      
      }
      .to-do-container{
        padding-left: 0;
      }
      div.todos-container{
        height: auto;
        overflow-y: auto;
        width:100%;
        padding-top: 0 !important;
        position:absolute;
        top:120px;
      }

      .homepage-h1{
        height: 42px;
      }
      .todo-li{
        width:75% !important;
        margin: 0 auto;
      }
      footer{
        position:relative !important;
        bottom:0 !important;
        margin-top: 0px;
       
      }
      .welcome > h1{
        font-size: 40px;
z-index: 1;
left:0;
top:0;    
 
 }
      .login-register-container{
        /* position: relative; */
        left:0;
        right: 0;
        top:0 !important;
        /* position: relative; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 138px 0px 60px 0px !important;
    /* left: 36%; */
    height: 100%;
    /* top: 0px; */
    width: 100%;
    background-image: url("./components/welcome-view/img/background.jpg");
    background-size: 140% 150%;
    background-attachment: fixed;
    margin-bottom:0;
      }
  }

  @media screen and (min-width:992px){
    .homepage-h1{
      padding:30px 0 0 0;
    }
  }

  @media screen and (max-width:991.5px){
    .homepage-h1{
      padding:30px 0 0 0;
    }
  }

  @media screen and (max-width:312px){
    .homepage-h1{
      font-size: 20px;
    }
  }